import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { IUserProfile, PlatformApiService } from '@shared/services/platform-api.service';
import { of } from 'rxjs';
import { catchError, flatMap, map } from 'rxjs/operators';
import { UserActions } from '../actions';
import { AppState } from '../reducers';
import { userProfile } from '../selectors/user.selector';

@Injectable()
export class UserEffects {
  constructor(private actions$: Actions, private readonly coreApiService: PlatformApiService, private store: Store<AppState>) {}

  /**
   * The effect being used
   */
  $loadUserProfile = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.GET_OR_LOAD_PROFILE),
      flatMap(() => this.store.pipe(select(userProfile))),
      flatMap(profile => {
        if (profile) {
          return of(profile);
        } else {
          return this.coreApiService.getUserProfile();
        }
      }),
      map((profile: IUserProfile) => UserActions.LOADED_PROFILE({ profile })),
      catchError(error => of(UserActions.FAILED()))
    )
  );
}
