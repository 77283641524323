import { createReducer, on, createAction, props } from '@ngrx/store';
const EMPTY = createAction('[Empty Action]');
const UNAUTHORIZED = createAction('[Unauthorized User]');
/* eslint-disable */
const SUCCESS_NOTIFICATION = createAction(
  '[Success Notification]',
  props<{ message: string }>()
);
const BaseReducer = createReducer(
  {},
  on(EMPTY, state => state),
  on(UNAUTHORIZED, state => state),
  on(SUCCESS_NOTIFICATION, state => state)
);
export { EMPTY, UNAUTHORIZED, SUCCESS_NOTIFICATION, BaseReducer };
