import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { LoaderService } from '@shared/services/loader.service';
import { UserService } from '@shared/services/user.service';
import { of } from 'rxjs';
import { catchError, flatMap, map, tap } from 'rxjs/operators';
import { PermissionActions } from '../actions';
import { AppState } from '../reducers';
import { userPermissions } from '../selectors/permission.selector';

@Injectable()
export class PermissionEffects {
  constructor(
    private actions$: Actions,
    private readonly userService: UserService,
    private readonly loader: LoaderService,
    private store: Store<AppState>
  ) {}

  /**
   * The effect being used
   */
  $loadUserPermission = createEffect(() =>
    this.actions$.pipe(
      ofType(PermissionActions.GET_OR_LOAD),
      flatMap(() => this.store.pipe(select(userPermissions))),
      flatMap(profile => {
        if (profile) {
          return of(profile);
        } else {
          return of(1).pipe(
            tap(() => this.loader.show()),
            flatMap(() => this.userService.permissions()),
            tap(() => this.loader.hide())
          );
        }
      }),
      map((permissions: string[]) => PermissionActions.LOADED({ permissions })),
      catchError(e => {
        this.loader.hide();
        if (e.status && e.status === 403) {
          return of(PermissionActions.LOADED({ permissions: [] }));
        } else {
          return of(PermissionActions.FAILED());
        }
      })
    )
  );
}
