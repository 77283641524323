import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AuthConfig, OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { authConfig } from './auth.config';
import { AuthExpiredInterceptor } from '@app/interceptor/auth-expired.interceptor';
import { environment } from '@environments/environment';
import { InitialAuthService } from './initial-auth.service';
import { WINDOW, LOCATION } from '@shared/injections';
import { WindowFactory, LocationFactory, StorageFactory } from '@shared/factories';

@NgModule({
  imports: [
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [environment.apiBaseUrl, environment.core.api, environment.sso.api],
        sendAccessToken: true,
      },
    }),
  ],
  providers: [
    InitialAuthService,
    { provide: WINDOW, useFactory: WindowFactory },
    { provide: LOCATION, useFactory: LocationFactory },
    { provide: AuthConfig, useValue: authConfig },
    { provide: HTTP_INTERCEPTORS, useClass: AuthExpiredInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: (initialAuthService: InitialAuthService) => () => initialAuthService.initAuth(),
      deps: [InitialAuthService],
      multi: true,
    },
    { provide: OAuthStorage, useFactory: StorageFactory }
  ],
})
export class AuthModule {}
