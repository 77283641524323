import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { PLATFORM_API, PLATFORM_VERSION, API_AUTH_TOKEN } from '../injections';

interface Guid extends String {
    // unused, adds compile time errors when trying to go String->Guid
    __guid: any;
}

interface DateTime extends String {
    // unused, adds compile time errors when trying to go String->DateTime
    __dateTimeString: any;
}

export interface INotification {
    applicationClientId: string;
    content: string;
    occurredAt: DateTime;
    targetURL: string;
}

export interface IApplication {
    uid: Guid;
    manifest: {
        name: string;
        id: string;
        availability: {
            myWorkspace: boolean;
        };
    };
    configuration: {
        startURL: string;
        defaultStartURL: string;
    };
}

export interface IUserProfile {
    identity: {
        uid: string;
        fullName: string;
        organizationGUK: string;
        organisationName: string;
        email: string;
        deafultLanguageCode: string;
    };
}

export interface IBulletin {
    uid: Guid;
    message: string;
    applicationUID: Guid;
    type: 'danger' | 'warning' | 'info' | 'success';
    startTime: DateTime;
    endTime: DateTime;
    version: string;
}

export interface IHasAdminAccess {
    hasAdminAccess: boolean;
}

@Injectable({ providedIn: 'root' })
export class PlatformApiService {
    requestOptions: {headers: HttpHeaders};
    constructor(
        private readonly http: HttpClient,
        @Inject(PLATFORM_API) private readonly baseUrl: string,
        @Inject(PLATFORM_VERSION) private readonly version: string,
        @Inject(API_AUTH_TOKEN) private readonly apiToken: string) {

        const headerDict = {
            Authorization: `Bearer ${this.apiToken}`
        };

        this.requestOptions =  {
          headers: new HttpHeaders(headerDict),
        };
    }

    public getUnreadNotifications(take: number): Observable<INotification[]> {
      return this.http
          .get<INotification[]>(`${this.baseUrl}/notifications/unread?top=${take}&api-version=${this.version}`, this.requestOptions);
    }

    public getUserProfile(): Observable<IUserProfile> {
        return this.http
          .get<IUserProfile>(`${this.baseUrl}/accounts/user?api-version=${this.version}`, this.requestOptions);
    }

    public getInstalledApplications(): Observable<IApplication[]> {
        return this.http
          .get<IApplication[]>(`${this.baseUrl}/applications/installed?api-version=${this.version}`, this.requestOptions);
    }

    public getPrivileges(): Observable<string[]> {
        return this.http
          .get<string[]>(`${this.baseUrl}/applications/cpaCloud.platform/privileges?api-version=${this.version}`, this.requestOptions);
    }

    public getActiveBulletins(): Observable<IBulletin[]> {
        return this.http
          .get<IBulletin[]>(`${this.baseUrl}/applications/bulletins/active?api-version=${this.version}`, this.requestOptions);
    }

    public getHasAccessToIppAdminArea(): Observable<IHasAdminAccess> {
        return this.http
        .get<IHasAdminAccess>(`${this.baseUrl}/user/hasaccesstoippadminarea?api-version=${this.version}`, this.requestOptions);
    }
}
