import { AuthConfig } from 'angular-oauth2-oidc';
import {environment} from '@environments/environment';

// TODO: pull stuff from environment config
export const authConfig: AuthConfig = {

  // Url of the Identity Provider
  loginUrl: environment.sso.authorizeUrl,

  tokenEndpoint: environment.apiBaseUrl + '/oauth2/token',
  revocationEndpoint: environment.apiBaseUrl + '/oauth2/revoke',

  // URL of the SPA to redirect the user to after login
  redirectUri: environment.origin + '/index.html',

  // The SPA's id. The SPA is registered with this id at the auth-server
  clientId: environment.sso.clientId,

  responseType: 'code',

  showDebugInformation: true,
  scope: 'user',
  oidc: false,
};
