import { OAuthStorage } from 'angular-oauth2-oidc';

export function WindowFactory(): Window {
  return window;
}

export function LocationFactory(): Location {
  return location;
}

export function StorageFactory(): OAuthStorage {
  return localStorage;
}
