import { InjectionToken } from '@angular/core';
import * as fromRouter from '@ngrx/router-store';
import { Action, ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import { BaseReducer } from './base.reducer';
import * as GroupReducer from './group.reducer';
import { groupFeatureKey, GroupState } from './group.reducer';
import * as PermissionReducer from './permission.reducer';
import { permissionFeatureKey, PermissionState } from './permission.reducer';
import * as UserReducer from './user.reducer';
import { userFeatureKey, UserState } from './user.reducer';

export interface AppState {
  /**
   * This is the state of the Angular Router,
   * thereby allowing executions on state update
   */
  router: fromRouter.RouterReducerState<any>;
  [userFeatureKey]: UserState;
  [groupFeatureKey]: GroupState;
  [permissionFeatureKey]: PermissionState;
}

export const reducers: InjectionToken<ActionReducerMap<AppState, Action>> = new InjectionToken<ActionReducerMap<AppState, Action>>(
  'Root reducers token',
  {
    factory: () => ({
      router: fromRouter.routerReducer,
      base: BaseReducer,
      [userFeatureKey]: UserReducer.reducer,
      [groupFeatureKey]: GroupReducer.reducer,
      [permissionFeatureKey]: PermissionReducer.reducer
    })
  }
);

/**
 *  This is a logger used for local development. This can be used in addition to
 * the redux devtools chrome extension
 * @param reducer The reducer currently acting
 */
export function logger(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
  return (state, action) => {
    const result = reducer(state, action);
    console.groupCollapsed(action.type);
    // console.log('prev state', state);
    // console.log('action', action);
    // console.log('next state', result);
    console.groupEnd();

    return result;
  };
}
export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [logger] : [];
